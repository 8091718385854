import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import { Container, Row, Col, } from 'reactstrap';

import Main from '../../layout/main';
import ProjectNotFoundPage from "../notFound/projectNotFound";
import QuestionAnswers from "../../components/projectDetails/questionAnswers";
import AvailableAndInbiddingBideDetails from "../../components/projectDetails/bidDetailsAsideOne";
import ContactedBidDetails from "../../components/projectDetails/BidDetailsAsideThree";
import CompletedAndHiredBidDetails from "../../components/projectDetails/BidDetailsAsideFour";
import ProjectsSlider from "../../components/projects/projectslider";
import ProjectDetailsLoader from "../../components/projectDetails/projectDetailsSkeleton";
import RelatedProjects from "../../components/projectDetails/relatedProjects";
import Sticky from 'react-sticky-el';
import PDFViewer from "./pdfViewer";
import Bookmark from "../../components/projectDetails/bookmark";
import TheStatus from "../../components/projectDetails/TheStatus";
import IgnoreProject from "../../components/projectDetails/ignoreProject";
import CustomerDetails from "../../components/projectDetails/customerDetails";
import CancelProject from "../../components/projectDetails/cancelProject";
class Projectdetail extends Component {

    state = {
        isOpen: false,
        BidType: "",
        BidAmount: "",
        BidHourly: "",

        BidPriceEror: "",
        BidTypeEror: "",
        BidHourlyError: "",
        showCopy: false,
    }
    componentDidMount() {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    EditBidToggler() {
        this.setState({ isOpen: !this.state.isOpen });
    }
    checkIncludes = (question_answers) => {
        let includes = false;
        question_answers.forEach(element => {
            if (element.question_type === 7 && element.answer != "-") {
                includes = true
            }
        })
        return includes;
    }

    PROJECTS_DETAILS = (question_answers) => {
        return question_answers && (
            <>
                {question_answers.map((data, i) => {
                    if (data.question_type === 7) {
                        if (data.answer !== "-") {
                            const measure = JSON.parse(data.answer).measurements;
                            return measure.map((data, t) => data != null && (
                                <QuestionAnswers
                                    key={t}
                                    type="dimension"
                                    DimensionTitle={data.title}
                                    lengthDimension={data.measuring_length}
                                    widthDimension={data.measuring_breadth}
                                    heightDimension={data.measuring_height}
                                    unit={data.unit}
                                />
                            ));
                        }
                    } else if (data.question_type === 4) {
                        return (
                            <QuestionAnswers
                                key={i}
                                ProjectQuestionIndex={i + 1}
                                ProjectQuestion={data.question}
                                ProjectAnswer={data.answer === true ? "Yes" : "No"}
                                type=""
                            />
                        );
                    } else if (data.question_type === 6) {
                        return (
                            <QuestionAnswers
                                key={i}
                                ProjectQuestionIndex={i + 1}
                                ProjectQuestion={data.question}
                                ProjectAnswer={data.answer.join(", ")}
                                type=""
                            />
                        );
                    } else {
                        return (
                            <QuestionAnswers
                                key={i}
                                ProjectQuestionIndex={i + 1}
                                ProjectQuestion={data.question}
                                ProjectAnswer={data.answer}
                                type=""
                            />
                        );
                    }
                })}
            </>
        )
    };
    showNotification = () => {
        this.setState({ showCopy: true });
        const stateSetting = () => {
            this.setState({ showCopy: false });
        }
        setTimeout(function () {
            stateSetting();
        }, 3000);
    };
    copyToClipBoard = (data) => {
        this.showNotification();
        navigator.clipboard.writeText(data);
    };
    render() {
        const { from, projectDataNotFound, projectData, projectDataLoading, projectDataError, commissionDetails, projectVideos, projectFiles } = this.props;
        const {
            address,
            consumer_avatar_url,
            consumer_name,
            created_at,
            description,
            budget,
            files,
            bid_amount,
            project_type,
            title,
            no_of_bids,
            question_answers,
            pdf,
            roof_scope,
            bid_type,
            hours,
            bid_detail,
            id,
            project_id,
            status,
            consumer_mobile,
            pending_amount,
            down_payment,
            lead_fee,
            project_post_type,
            provider_id,
            project_status,
            message_count,
            unread_msg,
            full_address,

            bid_id,
            bid_breakdown_document,
            amount_receive_from_customer,
            total_amount,
            easybids_commission,
            public_project_id,
            project_thumbnail,
            provider_project_id,
            is_commission_paid,
            amount_payable_to_easybids_after_discount,
            can_provider_contact_customer,
            provider_project_status,
            is_bookmarked,
            already_hired,
        } = projectData;
        console.log(id, "status in the render of project details", provider_project_id, project_status)
        const capitalize = (str) => {
            const str2 = str && str.charAt(0).toUpperCase() + str.slice(1);
            return str2
        };


        return (
            <div>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Project Details</title>
                    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
                </Helmet>
                <Main additionalClass_main="product-details pb-0">
                    <section className="ebw-section__pages">
                        <Container className="ebw-prodet__container">
                            {projectDataNotFound && projectDataNotFound ?
                                <ProjectNotFoundPage /> :
                                <>
                                    {projectDataLoading ?
                                        <ProjectDetailsLoader loading={projectDataLoading} />

                                        :

                                        <>
                                            <div className="ebw-prodet">
                                                <div className="ebw-prodet__inwrap d-md-flex align-items-center justify-content-start">
                                                    <div className="d-flex align-items-center">
                                                        <div className="ebw-prodet__back-link d-flex align-items-center">
                                                            <span className="ebw-prodet__angle ebw-icon ebw-icon--lg icon-arrow-left" aria-hidden="true"></span>
                                                            {from === "lead_projects" ?
                                                                <Link to="/buy-projects"><div className="ebw-prodet__text">Pro+ projects</div></Link> :
                                                                from === "activities" ?
                                                                    <Link to="/activities"><div className="ebw-prodet__text">My projects</div></Link> :
                                                                    from === "my_projects" ?
                                                                        <Link to="/projects/all/1"><div className="ebw-prodet__text">Available projects</div></Link> :
                                                                        <Link to="/projects/all/1"><div className="ebw-prodet__text">Available projects</div></Link>
                                                            }
                                                        </div>
                                                        <div className="ebw-prodet__title pl-3 pl-md-6 ebw-title__primary d-none d-md-block">{capitalize(title)}</div>
                                                    </div>

                                                    <div className="ebw-prodet__more d-flex align-items-start justify-content-between ml-3">
                                                        <div className="ebw-prodet__title ebw-title__primary d-block d-md-none">{capitalize(title)}</div>

                                                        {/* <div className="ebw-prodet__more-text d-flex align-items-center">
                                                            <span className="pr-2">More</span>
                                                            <span className="ebw-icon ebw-icon--lg icon-more-horizontal ebw-prodet__more-icon"></span>
                                                        </div> */}
                                                    </div>
                                                    <IgnoreProject
                                                        project_status={project_status}
                                                        id={id}
                                                        bid_amount={bid_amount}
                                                        consumer_mobile={consumer_mobile}
                                                    />
                                                    {project_status == 3 &&
                                                        <CancelProject
                                                            project_id={id}
                                                            provider_project_id={provider_project_id}
                                                        />
                                                    }
                                                </div>
                                            </div>
                                            <div className="test_scroll">
                                                <Row className="ebw-prodet__row py-6 py-md-12">
                                                    <Col lg="6">
                                                        <div className="ebw-card ebw-card__others ebw-card__others--shadow">
                                                            <div className="ebw-prodet__slider">
                                                                <ProjectsSlider
                                                                    projectFiles={projectFiles}
                                                                    files={projectData.files}
                                                                // videos={projectVideos}
                                                                />
                                                            </div>
                                                            <div className="mt-3 ebw-prodet__content position-relative">
                                                                <div className="d-flex justify-content-between w-100">
                                                                    <div>
                                                                        <div className="ebw-prodet__name">{capitalize(title)}</div>
                                                                        <div className="ebw-prodet__date ebw-card__date">{created_at}</div>
                                                                    </div>
                                                                    <Bookmark
                                                                        bookmarked={is_bookmarked}
                                                                        project_id={id}
                                                                        title={title}
                                                                        project_type={project_type}
                                                                        project_thumbnail={project_thumbnail}
                                                                        additionalClass="mb-0 position-absolute r-0"
                                                                    />
                                                                </div>
                                                                <div className="ebw-prodet__subtitle ebw-card__subtitle">{project_type}</div>
                                                                {public_project_id &&
                                                                    <div className="ebw-prodet__id-wrap d-flex align-items-center mt-4 mb-4">
                                                                        <div className="d-flex align-items-center mr-2">
                                                                            <div className="icon-info es-icon__size-xl es-icon__color--lightgray"><img src="/images/icons/icon-information-circle-outline.svg" alt="" /></div>
                                                                            <div className="ebw-prodet__id-label ml-1">Project ID:</div>
                                                                        </div>
                                                                        <div className="ebw-prodet__id d-flex align-items-center w-60">
                                                                            <input className="ebw-prodet__id-input" type="text" value={public_project_id} id="projectId" disabled="disabled" readonly />
                                                                            <div onClick={() => this.copyToClipBoard(public_project_id)} className="icon-copy-url es-icon__size-sm ml-1"><img src="/images/icons/icon-copy.svg" /></div>
                                                                        </div>
                                                                        <div className={this.state.showCopy ? "dblock ebw-prodet__id-notification" : "d-none"}>
                                                                            Copied
                                                                        </div>
                                                                    </div>
                                                                }

                                                                <TheStatus
                                                                    consumer_mobile={consumer_mobile}
                                                                    project_status={project_status}
                                                                />


                                                                <div className="mt-6">
                                                                    <div className="ebw-prodet__title ebw-prodet__title-project">Project Description</div>
                                                                    <div className="ebw-prodet__description pt-2">
                                                                        {description}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="ebw-card ebw-card__others ebw-card__others--shadow mt-4">

                                                            <CustomerDetails
                                                                consumer_mobile={consumer_mobile}
                                                                can_provider_contact_customer={can_provider_contact_customer}
                                                                unread_msg={unread_msg}
                                                                provider_id={provider_id}
                                                                project_id={id}
                                                                consumer_avatar_url={consumer_avatar_url}
                                                                full_address={full_address}
                                                                consumer_name={consumer_name}
                                                                public_project_id={public_project_id}
                                                                project_thumbnail={project_thumbnail}
                                                                title={title}
                                                                project_type={project_type}
                                                            />
                                                        </div>
                                                    </Col>

                                                    <Col sm="12" lg="6">
                                                        <Sticky
                                                            stickyClassName="ebw-sticky ebw-sticky__project-details"
                                                            className="w-100 h-100 ebw-sticky__wrap w-100"
                                                            stickyStyle={{ marginTop: "90px" }}
                                                            topOffset={-80}
                                                            bottomOffset={100}
                                                            boundaryElement=".test_scroll"
                                                            hideOnBoundaryHit={false}
                                                        >
                                                            {project_status != "9" &&
                                                                <div className="ebw-card ebw-card__others h-100">
                                                                    {(project_status && (project_status == "2" || (project_status == "3" && bid_amount == "$0.00"))) ?
                                                                        <AvailableAndInbiddingBideDetails
                                                                            editMode={bid_amount == "$0.00" ? false : true}
                                                                            bid_amount={bid_amount}
                                                                            bid_type={bid_type}
                                                                            project_status={project_status}
                                                                            hours={hours}
                                                                            bid_detail={bid_detail}
                                                                            commissionDetails={commissionDetails}
                                                                            status={status}
                                                                            id={id}
                                                                            created_at={created_at}
                                                                            project_type={project_type}
                                                                            title={title}
                                                                            provider_id={provider_id}
                                                                            project_id={id}
                                                                            message_count={message_count}
                                                                            unread_msg={unread_msg}
                                                                            providr_bid_id={bid_id}
                                                                            bid_breakdown_document={bid_breakdown_document}
                                                                            amount_receive_from_customer={amount_receive_from_customer}
                                                                            total_amount={total_amount}
                                                                            easybids_commission={easybids_commission}
                                                                            public_project_id={public_project_id}
                                                                            project_thumbnail={project_thumbnail}
                                                                            consumer_name={consumer_name}
                                                                            full_address={full_address}
                                                                            consumer_avatar_url={consumer_avatar_url}
                                                                            consumer_mobile={consumer_mobile}
                                                                            can_provider_contact_customer={can_provider_contact_customer}
                                                                            already_hired={already_hired}
                                                                        /> : (project_status == "3" || project_status == "6" || project_status == "4") &&
                                                                        <CompletedAndHiredBidDetails
                                                                            bid_amount={bid_amount}
                                                                            bid_type={bid_type}
                                                                            hours={hours}
                                                                            bid_detail={bid_detail}
                                                                            commissionDetails={commissionDetails}
                                                                            status={status}
                                                                            title={title}
                                                                            created_at={created_at}
                                                                            pending_amount={pending_amount}
                                                                            down_payment={down_payment}
                                                                            id={id}
                                                                            project_id={project_id}
                                                                            project_status={project_status}
                                                                            project_post_type={project_post_type}
                                                                            budget={budget}
                                                                            provider_project_id={provider_project_id}
                                                                            is_commission_paid={is_commission_paid}
                                                                            amount_payable_to_easybids_after_discount={amount_payable_to_easybids_after_discount}
                                                                            consumer_mobile={consumer_mobile}
                                                                            provider_project_status={provider_project_status}
                                                                            total_amount={total_amount}
                                                                            easybids_commission={easybids_commission}
                                                                            amount_receive_from_customer={amount_receive_from_customer}
                                                                            unread_msg={unread_msg}
                                                                            provider_id={provider_id}
                                                                        />
                                                                    }
                                                                </div>
                                                            }
                                                            {((question_answers && question_answers.length > 0) || roof_scope) &&
                                                                <Row className="">
                                                                    <Col sm="12" className="pt-3 pt-md-6 ebw-prodet__all">
                                                                        {question_answers && question_answers.length != 0 &&
                                                                            <Row>
                                                                                <Col sm="12">
                                                                                    <div className="ebw-prodet__title ebw-prodet__title-project">Project Details</div>
                                                                                </Col>
                                                                            </Row>
                                                                        }
                                                                        <Row className="pt-3">
                                                                            {question_answers && this.checkIncludes(question_answers) &&
                                                                                <Col md={12} className="mb-3">
                                                                                    <div className="ebw-card ebw-card__others">
                                                                                        <div className="ebw-prodet__subtitle mb-2"><b>Dimensions</b></div>
                                                                                        <div className="ebw-prodet__dimension-wrap">
                                                                                            {question_answers && question_answers.length != 0 &&
                                                                                                this.PROJECTS_DETAILS(question_answers && question_answers.filter(x => x.question_type === 7))}
                                                                                        </div>
                                                                                    </div>
                                                                                </Col>
                                                                            }

                                                                            <Col md={12} className="d-md-flex  mb-3">
                                                                                <div className="ebw-prodet__qa ebw-card ebw-card__others ">
                                                                                    {question_answers && question_answers.length != 0 &&
                                                                                        this.PROJECTS_DETAILS(question_answers.filter(x => x.question_type === 4 || x.question_type === 6 || x.question_type === 3 || x.question_type === 1 || x.question_type == 2 || x.question_type === 5))}
                                                                                </div>
                                                                            </Col>

                                                                            {roof_scope &&
                                                                                <Col md={12} className="">
                                                                                    <div className="ebw-card ebw-card__others">
                                                                                        {pdf !== false ?
                                                                                            <div className="ebw-prodet__roofscope">
                                                                                                <div className="d-flex align-items-center mt-1 mb-1 ebw-prodet__roofscope-text">
                                                                                                    <span className="ebw-icon ebw-icon--md icon-document ml-2"></span>
                                                                                                    <span className="ml-1 pt-1 pb-1">Roofscope data available now.</span>
                                                                                                </div>

                                                                                                <embed src={pdf} width="400px" height="500px" />
                                                                                                {/* <PDFViewer url={pdf} /> */}
                                                                                            </div>
                                                                                            :
                                                                                            <div className="d-flex align-items-center mt-1 mb-1 ebw-prodet__roofscope-text ebw-prodet__roofscope-text--red">
                                                                                                <span className="ebw-icon ebw-icon--md icon-document ml-2"></span>
                                                                                                <span className="ml-1 pt-1 pb-1">Roofscope data not available now.</span>
                                                                                            </div>
                                                                                        }
                                                                                    </div>
                                                                                </Col>
                                                                            }
                                                                        </Row>

                                                                    </Col>

                                                                </Row>
                                                            }
                                                        </Sticky>
                                                    </Col>
                                                </Row>
                                                {/* <div className="mt-6">
                                                <div className="ebw-prodet__title ebw-prodet__title-project">Project Description</div>
                                                <div className="ebw-prodet__description pt-4">
                                                    {description}
                                                </div>
                                            </div> */}

                                            </div>

                                            <Row>
                                                <Col>
                                                    <RelatedProjects />
                                                </Col>
                                            </Row>
                                        </>
                                    }
                                </>
                            }
                        </Container>
                    </section>
                </Main>

            </div>
        );
    }
}

export default Projectdetail;

