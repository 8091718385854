import React from "react";
import { IonSlide, IonSlides } from "@ionic/react";
import { Link } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { activateChatBox } from "../../redux/actions/chatboxActive";
import { getChat } from "../../redux/actions/getChat";
// import { closePrimaryNotifications } from "../../redux/reduxes/dashboard/closeNotificationAction";

const NotificationCardChat = (props) => {
  const dispatch = useDispatch();
  const { parameter, project_name, removeNotification, onModal } = props;

  const activateChat = (project_id, provider_id, user_id) => {
    const data = {
      provider_id: provider_id,
      project_id: project_id
    };
    const activateData = {
      provider_id: provider_id,
      project_id: project_id,
      active: true
    };
    dispatch(activateChatBox(activateData));
    dispatch(getChat(data));
    props.closeNotifications()
  };

  return (
    <div className="ebw-notification__slide ebw-notification__slide--notification projects mb-5" >
      <div
        className="d-flex align-items-center"
      >
        <div onClick={() => activateChat(parameter.project_id, parameter.provider_id, parameter.user_id)} className="ebw-notification__slide-left">
          <div className="ebw-pulse__icon ebw-pulse__icon--primary">
            <div className="circle"><img src={`/images/primaryNoti/notification-1e.svg`} /></div>
          </div>
        </div>
        <div onClick={() => activateChat(parameter.project_id, parameter.provider_id, parameter.user_id)} className="ebw-notification__slide-right">
          <p className="ebw-notification__slide-heading">
            Hey! You got a message from the customer for the project.
          </p>
          <p className="ebw-notification__slide-project">
            <b className="ebw-notification__slide-project-name">
              {parameter.project_name ? parameter.project_name : "Project Name"}
            </b>
          </p>
          <div>
            <div className="d-flex">
              <div>View Chat</div> <img src="/assets/images/icons/arrow-right.svg" className="ml-2" alt="" />
            </div>
          </div>
        </div>
        {onModal &&
          <div className="icon-close ebw-icon__size-sm ebw-notification__close-icon" onClick={() => removeNotification(props.id)}></div>
        }
      </div>
    </div>
  );
};
export default NotificationCardChat;

