import React from "react";
import { IonSlide, IonSlides } from "@ionic/react";
import { Link } from "react-router-dom";
import { useDispatch } from 'react-redux';

// import { closePrimaryNotifications } from "../../redux/reduxes/dashboard/closeNotificationAction";
const NotificationSlideNotYetHired = (props) => {
    const dispatch = useDispatch();
    const { parameter, project_name, removeNotification, onModal } = props;
    const getRedirectData = (param) => {
        const redirectData = {
            pathname: `/project-detail/${param}/my_projects`
        };
        return redirectData;
    }

    return (
        <div className="ebw-notification__slide ebw-notification__slide--projects projects mb-5" >
            <div
                className="d-flex align-items-center justify-content-start flex-wrap"
                to={(getRedirectData(parameter ? parameter.project_id : 0))}
            //   onClick={() => { dispatch(closePrimaryNotifications(props.id)); props.closeNotifications() }}
            >
                <Link to={(getRedirectData(parameter ? parameter.project_id : 0))} className="ebw-notification__slide-left">
                    <div className="ebw-pulse__icon ebw-pulse__icon--warning">
                        <div className="circle"><img src={`./images/primaryNoti/notification-1a.svg`} className="my-auto" /></div>
                    </div>
                </Link>
                <Link to={(getRedirectData(parameter ? parameter.project_id : 0))} className="ebw-notification__slide-right">
                    <p className="ebw-notification__slide-heading"> Congratulations! | <b className="ebw-notification__slide-project-name">
                        {parameter.project_name ? parameter.project_name : "Project Name"}
                    </b></p>
                    <p className="ebw-notification__slide-project"> You have been hired by the customer. </p>
                    <div>
                        <div className="d-flex">
                            <div>View Project</div> <img src="/assets/images/icons/arrow-right.svg" className="ml-2" alt="" />
                        </div>
                    </div>
                </Link>
                {onModal &&
                    <div className="icon-close ebw-icon__size-sm ebw-notification__close-icon" onClick={() => removeNotification(props.id)}></div>
                }
            </div>
        </div>

    );
};
export default NotificationSlideNotYetHired;
