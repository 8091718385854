import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Badge, Button, Modal, Form, ModalHeader, ModalBody, } from 'reactstrap';
import { useDispatch } from 'react-redux';
import Textarea from "../../components/inputs/textarea";
import { markCancelProject } from "../../redux/actions/markCancelProject";

const CancelProject = ({ project_id, provider_project_id }) => {
    const dispatch = useDispatch();
    const [cancelProjectPop, setCancelProjectPop] = useState(false);
    const [reason, setReason] = useState([]);
    const reasonInputHanler = (event) => {
        setReason(event.target.value);
    }
    const cancelProject = (event) => {
        event.preventDefault();

        const data = JSON.stringify({
            cancellation_request: {
                description: reason,
                requested_user_type: "2",
                status: 1,
                project_id: project_id,
                provider_project_id: provider_project_id,
            }
        });
        dispatch(markCancelProject(data));
        setReason("");
        setCancelProjectPop(false);
    };
    return (
        <>
            < div >
                <Button onClick={() => setCancelProjectPop(true)} className="ebw-button ebw-button__border ebw-button__border--darkgrey py-1 px-3">
                    Cancel Project
                </Button>
            </div>
            <Modal isOpen={cancelProjectPop} toggle={() => setCancelProjectPop(false)}>
                <div className="px-5 py-5 ebw-modal__wrap">
                    <ModalHeader toggle={() => setCancelProjectPop(false)} className="ebw-profile__modal-head px-0 px-md-2 py-0" style={{ border: "none" }}>
                        <div className="ebw-modal__title text-center">Reason for cancellation</div>
                    </ModalHeader>
                    <ModalBody className="ebw-modal__body px-0 px-md-2">
                        <Form className="ebw-modal__form">
                            <div className="ebw-modal__input-wrap mb-4">
                                <Textarea
                                    changeEvent={(e) => reasonInputHanler(e)}
                                    name="reason"
                                    value={reason}
                                    id="uploadDP"
                                    className="ebw-modal__textarea py-2"
                                    label="Enter the reason for cancellation here."
                                />
                            </div>
                            <div className="text-right">
                                <button
                                    onClick={(e) => cancelProject(e)}
                                    className="ebw-modal__submit ebw-button__primary"
                                    type="submit"
                                >Update</button>
                            </div>
                        </Form>
                    </ModalBody>
                </div>
            </Modal>
        </>
    );
}

export default CancelProject;